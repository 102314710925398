import { gql } from '@apollo/client';
import { ReactNativeFile } from 'apollo-upload-client';

import client from './client';
import { UploadFile, File as FSFile } from '../types/file.interface';

type GraphUploadFileType = {

  upload: FSFile | FSFile[];

};

function generateRNFile(file: UploadFile) {
  const rnFile = new ReactNativeFile({
    uri: `file://${file.uri}`,
    type: file.type,
    name: file.name,
  });

  return rnFile;
}

const querySingleFile = gql`
  mutation ($file: Upload!) { 
    upload(file: $file) { 
      id,
      name,
      url,
    } 
  }
`;

const queryMultiFile = gql`
  mutation ($files: [Upload!]!) { 
    multiUpload(files: $files) {
      id,
      name,
      url,
    } 
  }
`;

const fileSystemUploadFiles = async (uploadFiles: UploadFile[]) => {
  const files = uploadFiles.map(generateRNFile);
  console.log(files);

  let mutation;
  let variables;

  if (files.length > 1) {
    mutation = queryMultiFile;
    variables = { files };
  } else {
    mutation = querySingleFile;
    variables = { file: files[0] };
  }

  try {
    const response = await client.mutate<GraphUploadFileType>({
      mutation,
      variables,
    });

    return response.data?.upload;
  } catch (e: any) {
    return [];
  }
};

export default fileSystemUploadFiles;
