import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from 'theme/colors';
import { Linking, StyleSheet } from 'react-native';
import {
  IS_CHROME_EXTENSION,
  IS_DESKTOP_WEB, IS_MOBILE, IS_MOBILE_WEB, IS_WEB,
} from 'domain/shared/constants';
import { ACTION_ENGINE_FILE_VIEWER } from 'env';
import { StageType } from 'domain/shared/types/engagement-types';
import ReviewPopup from 'components/organisms/review-popup';
import { Review } from 'domain/shared/types/review';
import { Reviews } from 'domain/shared/types/reviews';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';
import { RectButton, TouchableWithoutFeedback } from '../touchable';
import { openLinkOnNewTab } from 'domain/shared/utils/openOnNewTab';

interface IProps {
  leadId: string;
  messageId: string;
  linkPreview: string;
  engagementStatus: string;
  message: any;
  related_messages: any;
  title?: string;
}

const Container = styled(IS_WEB ? TouchableWithoutFeedback : RectButton)<IProps>`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${colors.BORDER_COLOR};
  justify-content: center;
  align-items: center;
  z-index: 9;
  cursor: pointer;
  background-color:${(props) => (props.engagementStatus === StageType.submitted
    && props.message.verb !== 'digital-card' ? colors.ORANGE : colors.WHITE)};
`;

const style = StyleSheet.create({
  rotate: {
    transform: [
      { rotate: '-90deg' },
    ],
  },
});

const LINK_ACTIONS = [
  'soft-pull',
  'get-review',
  'get-reviews',
  'view-vehicle',
  'digital-card',
];

const AttachmentButton = (props: IProps) => {
  const {
    leadId,
    messageId,
    linkPreview,
    engagementStatus,
    message,
    related_messages,
    title = '',
  } = props;

  const titleHeader = title || message.text;
  const [showModal, setShowModal] = useState<boolean>(false);

  const submitted = engagementStatus === StageType.submitted;
  const showLink = submitted && LINK_ACTIONS.includes(message.verb);
  const colorButton = submitted && message.verb !== 'digital-card' ? colors.WHITE : colors.BLACK;

  const icon = useMemo(() => {
    if (showLink) return 'link';
    if (submitted) return 'attachment';
    return 'link';
  }, [showLink, submitted]);


  const url = useMemo(() => {
    if (!submitted || showLink) return linkPreview;
    return `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadId}&mid=${messageId}`;
  }, [submitted, showLink]);

  const linkHandler = useCallback((link: string) => {
    if (IS_MOBILE_WEB || IS_MOBILE) {
      OpenInAppModalBrowser(url);
      return;
    }

    if (IS_CHROME_EXTENSION) {
      if (message.verb === 'soft-pull') {
        openLinkOnNewTab(link);
        return;
      }

      CRMExtensionService.openFileViewer(link, titleHeader || '');
    }

    if (IS_DESKTOP_WEB) {
      Linking.openURL(link);
      return;
    }
  }, []);

  const openReview = useCallback((review: Reviews) => {
    if (related_messages.length > 0) {
      const reviewItem = related_messages.find((r: any) => r.message.verb === 'get-review');

      if (reviewItem) {
        const { message: { data = '' } = {} } = reviewItem;
        const reviewFound:Review = JSON.parse(data);
        if (reviewFound && related_messages.length > 1) {
          if (review === Reviews.GOOGLE) {
            linkHandler(reviewFound.form.reviews_content.site_data.author_url);
            setShowModal(false);
          }
        }
      }
    }
  }, []);

  const openLink = useCallback(() => {
    if (related_messages
      && related_messages.some((r: any) => r.message.verb === 'get-review'
       && (r.message.data.length > 0))) {
      openReview(Reviews.GOOGLE);
      // setShowModal(true);
      return;
    }

    linkHandler(url);
  }, [url]);

  return (
    <Container
      {...props}
      onPress={openLink}
      hitSlop={{ top: 40, bottom: 40 }}
    >
      <Icon
        name={icon}
        size={16}
        color={colorButton}
        style={submitted && !LINK_ACTIONS.includes(message.verb) ? style.rotate : {}}
      />
      <ReviewPopup
        showModal={showModal}
        setShowModal={setShowModal}
        openReview={openReview}
      />
    </Container>
  );
};

export default AttachmentButton;
