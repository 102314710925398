import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components/native";
import { useEffectOnce } from "react-use";
import { Flex } from "@kanvas/andromeda";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";

// model
import Lead from "model/data/lead";
import LeadHubPresenter from "model/presenters/lead-hub.presenter";

// constants
import colors from "theme/colors";
import { translate } from "locales";
import { IS_WEB } from "domain/shared/constants";
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import { OpportunityScreens as OS } from 'domain/create-opportunity';
import { ACTIVE_SESSION } from "domain/shared/screens";
import { RootState } from "domain/shared/store";
import ReloadButton from "domain/opportunity-list/components/reload-button";
import { OPENING_OPPORTUNITY } from "domain/shared/types/amplitude-event";
import { sendAmplitudeEvent } from "domain/shared/utils/amplitude";
import { Opportunity } from "domain/shared/types/opportunity";
import { setActiveOpportunity } from "domain/shared/store/slice/opportunity";
import useEventReciever from "domain/shared/hooks/use-event-reciever";
import { OpportunityEvents } from "domain/shared/events/opportunity-list";
import usePusher from "domain/shared/hooks/use-pusher";

// components
import OppListFilter from "components/organisms/opp-list-filter";
import { LeadHudLoadingRef } from "components/organisms/lead-hud-loading";

import AddLeadButton from "domain/opportunity-list/components/add-lead-button";
import { REFRESH_LEAD_LIST_EVENT } from "domain/opportunity-list/screens/opp-list";
import ListHeader from "domain/lead-hub/components/list-header";
import LeadList from "domain/lead-hub/components/lead-list";
import crmLinking from "domain/lead-hub/use-cases/crm-linking";


function useLeadHub() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Lead[]>([]);
  const [limit, setLimit] = useState(false);
  const [reload, setReload] = useState(false);
  
  const loader = useRef<LeadHudLoadingRef>(null);

  const user = useSelector((state: RootState) => state.auth.user);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const presenter = useMemo(() => new LeadHubPresenter({
    get: (items) => {
      setItems([...items]);
      setLoading(false);
    },
    error: () => console.log('error'),
    notFound: () => loader.current?.notFound(),
    limitReach: () => setLimit(true),
  }), []);

  const handleLeadPress = (lead: Lead) => {
    const { title, id, uuid, link_sources } = lead.backendEntity;

    sendAmplitudeEvent(OPENING_OPPORTUNITY, {
      event: OPENING_OPPORTUNITY,
      lead_title: title,
      lead_id: id,
      lead_uuid: uuid,
    });

    dispatch(setActiveOpportunity(lead.backendEntity as unknown as Opportunity));
    crmLinking(lead);

    // @ts-ignore
    navigation.navigate(OpportunityScreens.WALL, ACTIVE_SESSION);
  };

  const clear = (fn: VoidFunction) => {
    setItems([]);
    setLoading(true);
    setLimit(false);
    setReload(false);

    fn();
    loader.current?.reset();
  }

  const handleRefresh = () => clear(() => presenter.refetch());

  // use just in web
  const handleSearch = (text: string) => {
    if (!IS_WEB) return;
    // @ts-ignore
    navigation.navigate(OpportunityScreens.SEARCH, { text });
  }

  const handlePagination = () => {
    if (loading) return;
    setLoading(true);
    presenter.next();
  }

  const handleAdd = () => {
    // @ts-ignore
    navigation.navigate(OpportunityScreens.CREATE, {
      screen: OS.ADD,
      params: { type: 'lead' },
    });
  }

  const handle = () => {
    if (loading) return;
    setReload(true);
  }

  useEffectOnce(() => presenter.fetch());
  
  // FIXME: in future version prevent the refresh been done like this
  useEventReciever(REFRESH_LEAD_LIST_EVENT, handleRefresh);
  useEventReciever(OpportunityEvents.REFRESH, handleRefresh);

  const subscribeKey = `leads_list_${user?.default_company_branch}`;
  usePusher({ subscribeKey, bindKey: 'newLead' }, { handle }, [subscribeKey, loading]);

  return {
    model: {
      items,
      loading,
      loader,
      limit,
      reload,
    },
    operations: {
      handleRefresh,
      handleLeadPress,
      handlePagination,
      handleAdd,
      handleSearch,
    }
  }
}

export default function LeadHub() {
  const { model, operations } = useLeadHub();

  const { items, loading, loader, limit, reload } = model;
  const { handleRefresh, handlePagination, handleAdd, handleLeadPress, handleSearch } = operations;

  return (
    <Container>
      <ListHeader onSearch={IS_WEB ? handleSearch : undefined} />

      <FilterContainer>
        <OppListFilter routes={[{ key: 'my-opps', title: translate('myOpps') }]} />
      </FilterContainer>

      <LeadList 
        limit={limit}
        items={items}
        loader={loader}
        loading={loading}
        onRefresh={handleRefresh}
        onLeadPress={handleLeadPress}
        onPaginate={handlePagination}
      />
      
      <ReloadButton show={reload} onPress={handleRefresh} />
      <AddLeadButton onAdd={handleAdd} />
    </Container>
  );
}

const Container = styled(Flex)`
  flex: 1;
  background-color: ${colors.WHITE};
`;

const paddingTop = IS_WEB ? 10 : 0;
const FilterContainer = styled(Flex)
  .attrs(() => ({
    row: true,
    align: 'center',
  }))`
  padding: ${paddingTop}px 16px 10px;
  width: 100%;
`;