import { Channel } from "pusher-js";

import Task, { Status } from "model/data/task";

import client from "domain/shared/graphql/client";

import { TaskResponse, TasksQuery } from "./queries";
import { changeStatus } from "./mutation";
import { kanvasPusher } from "../pusher";

export default class TasksApi {
  async get(leadId: number): Promise<Task[]> {
    try {
      const response = await client.query<{ leadTaskItems: { data: TaskResponse[] } }>({ 
        query: TasksQuery,
        fetchPolicy: 'network-only',
        variables:  { leadId }
      });

      const { data } = response.data.leadTaskItems;

      return data.map((item) => Task.fromGraph(item));
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getSubscription(leadId: number, fn: () => void): VoidFunction {
    const channel = kanvasPusher.subscribe(`lead-tasks-${leadId}`);
    const bind = channel.bind('lead.tasks', fn);
    return () => {
      channel.unsubscribe();
      bind.unbind();
    };
  }

  async changeStatus(id: number, leadId: number, status: Status) {
    const response = await client.mutate<{ changeTaskEngagementItemStatus: boolean }>({
      mutation: changeStatus,
      variables: { id, leadId, status }
    });

    if (!response.data) return false;

    const { changeTaskEngagementItemStatus } = response.data;

    return changeTaskEngagementItemStatus;
  }
}