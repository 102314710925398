import { Flex } from '@kanvas/andromeda';
import React, {
  forwardRef,
  useCallback,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import FastImage from 'react-native-fast-image';
import styled from 'styled-components/native';
import { Meta2 } from 'components/molecules/text';
import Colors from 'theme/colors';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import Modal from 'react-native-modal';
import UploadCategories from 'organisms/upload-categories';
import items from 'organisms/upload-categories/items';
import { ButtonListItem } from 'organisms/button-list';
import { Opportunity } from 'domain/shared/types/opportunity';
import { File as FSFile } from 'domain/shared/types/file.interface';
import OpportunityService from 'domain/shared/services/opportunity-service';
import Toasts from 'react-native-toast-message';
import FileUpload, { UploadedFile, FileUploadRef } from 'components/atoms/file-upload';
import ToastMessage from 'components/molecules/Toast-upload-photo';
import Spinner from 'components/atoms/spinner';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { useDispatch } from 'react-redux';

import TextButton from './text-button';

interface IProps {
  onFileUploaded?: () => void;
  onCancelPress?: () => void;
  onFileSelected?: (file: UploadedFile) => void;
  opportunity: Opportunity;
}

export interface UploadLeadFilesRef {
  openCamera: () => void;
}

const PreviewImage = styled(FastImage)`
  width: 100%;
  flex: 1;
`;

const SpinnerContainer = styled(Flex)`
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  z-index: 9;
  background-color: ${Colors.SPINNER_OVERLAY_COLOR};
`;

const getImageBlob = (base64: string) => (
  fetch(base64).then((res) => res.blob())
);

const showNotification = (text: string, type: string = 'success') => {
  setTimeout(() => {
    Toasts.show({
      type,
      text1: `${text} ${translate('uploadMessage')}`,
    });
  }, 600);
};

const toastConfig = {
  success: ({ text1 }: any) => (
    <ToastMessage type="SUCCESS" message={text1} />
  ),
};

const UploadLeadFiles = forwardRef<UploadLeadFilesRef, IProps>((props, ref) => {
  const {
    onCancelPress,
    opportunity,
    onFileUploaded,
    onFileSelected,
  } = props;
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState<UploadedFile | undefined>(undefined);
  const [category, setCategory] = useState(items[0]);
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const fileUpload = useRef<FileUploadRef>({} as FileUploadRef);

  const { files = [], id, title } = opportunity;

  const onUploadCategorySelected = useCallback((item: ButtonListItem) => {
    setCategory(item);
  }, []);

  const openCamera = useCallback(() => {
    fileUpload.current?.openFileUpload();
  }, []);

  const takeAnother = useCallback(() => {
    setUploadImage(undefined);
    setUploaded(false);
    setLoading(false);
    openCamera();
  }, []);

  const uploadPhoto = useCallback(async () => {
    try {
      if (uploadImage) {
        setLoading(true);
        const formattedName = category.text.replaceAll(' ', '_') || '';
        const sequence = files.filter((file: FSFile) => (
          file.field_name?.includes(formattedName)
        )).length + 1;
        const blob = await getImageBlob(uploadImage.base64);
        const extension = uploadImage.type.split('/')[1];
        const name = `${formattedName}_${sequence.toString().padStart(3, '0')}`;
        const fileToUpload = new File([blob], `${name}.${extension}`, { type: uploadImage.type });
        const response = await OpportunityService.uploadPhoto(fileToUpload, name);

        const [file] = response;
        if (file) {
          const list = [
            {
              field_name: name,
              filesystem_id: file.id,
              id: 0,
              title,
            },
          ];
          const opp = await OpportunityService.associateFileToOpp(
            [...files, ...list] as any,
            id!,
            title,
          );
          dispatch(setActiveOpportunity(opp));
          showNotification(name);
          onFileUploaded?.();
          setUploaded(true);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setLoading(true);
      console.log(error);
    }
  }, [category, files, id, title, uploadImage]);

  const fileSelected = useCallback((file: UploadedFile) => {
    setVisible(true);
    setUploadImage(file);
    onFileSelected?.(file);
    setLoading(false);
  }, []);

  const onCancel = useCallback(() => {
    setVisible(false);
    setUploadImage(undefined);
    setUploaded(false);
    setLoading(false);
    onCancelPress?.();
  }, []);

  useImperativeHandle(ref, () => ({
    openCamera,
  }));

  return (
    <>
      <FileUpload
        ref={fileUpload}
        onFileSelected={fileSelected}
      />
      <Modal
        isVisible={visible}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{ margin: 0, backgroundColor: Colors.BLACK }}
      >
        <Flex flex={1}>
          <Flex flex={3}>
            {
              loading && (
                <SpinnerContainer gap={8} align="center" row flex={1}>
                  <Spinner />
                  <Meta2
                    text={translate('uploading')}
                    color={Colors.WHITE}
                  />
                </SpinnerContainer>
              )
            }

            <PreviewImage
              source={{ uri: uploadImage?.base64 }}
              resizeMode="contain"
              style={{
                flex: 1,
                width: '100%;',
              }}
            />
          </Flex>
          <Flex flex={1} justify="flex-end">
            {
              !uploaded && !loading && (
                <UploadCategories onCategorySelected={onUploadCategorySelected} />
              )
            }
            <Flex
              row
              justify="space-between"
              style={{
                margin: 32,
              }}
            >
              <TextButton
                text={translate(uploaded ? 'done' : 'cancel', TextTransform.CAPITAL)}
                onPress={onCancel}
                disabled={loading === true}
              />
              {
                uploaded ? (
                  <TextButton
                    text={translate('takeAnother', TextTransform.CAPITALIZE)}
                    onPress={takeAnother}
                  />
                ) : (
                  <TextButton
                    text={translate('upload', TextTransform.CAPITAL)}
                    onPress={uploadPhoto}
                    disabled={loading === true}
                  />
                )
              }
            </Flex>
            <Toasts config={toastConfig} position="top" />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
});

export default UploadLeadFiles;
