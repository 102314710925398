import { gql } from "@apollo/client";
import { LeadAction } from "domain/shared/types/lead-action";

type TaskCompanyAction = {
  id: number;
  name: string;
  status: string;
  description: string;
  action: LeadAction;
  form_config: Record<string, string> | null;
}

type TaskEngagement = {
  id: number;
  uuid: string;
  slug: string;
  message: {
    uuid: string;
    message: Record<string, any>;
  }
}

export interface TaskResponse {
  id: number;
  name: string;
  status: string;
  config?: Record<string, any>;
  company_action: TaskCompanyAction;
  engagement_start: TaskEngagement | null;
  engagement_end: TaskEngagement | null;
}

export const TasksQuery = gql`
  query LeadTasks($leadId: ID!) {
    leadTaskItems(lead_id: $leadId) {
      data {
        id
        name
        status
        config
        company_action {
          id
          status
          name
          description
          form_config
          action {
            id
            slug
            name
            icon
            is_active
            description
            form_fields
            form_config
          }
        }
        engagement_start {
          id
          uuid
          slug
          message {
            uuid
            message
          }
        }
        engagement_end {
          id
          uuid
          slug
          message {
            uuid
            message
          }
        }
      }
    }
  }
`;