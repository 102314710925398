/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
import {
  Platform,
  PlatformIOSStatic,
  StyleProp,
  TextStyle,
} from 'react-native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { NavigationContainer } from '@react-navigation/native';
import React, {
  useState, useContext, useCallback, useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import store from 'domain/shared/store';
import AuthStack from 'domain/auth';
import { SafeAreaProvider } from 'react-native-safe-area-context';
// import { BrowseInventoryStack } from 'domain/browse-inventory';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
// import codePush from 'react-native-code-push';
import { ApolloProvider } from '@apollo/client';
import { navigationRef } from 'domain/shared/services/navigation-service';
import CXTabBar, { CXHeader } from 'components/organisms/CXTabBar';
import { TabBarHeight } from 'components/organisms/CXTabBar/constants';
import { CSTabBarContextProvider, CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';
import colors from 'theme/colors';
import { FONTS } from 'theme/typography';
import { DEVICE_WIDTH, IS_ANDROID, IS_WEB } from 'domain/shared/constants';
import Toast from 'organisms/toast';
import CXLoadingOverlay from 'components/atoms/loading-overlay';
import { SearchingBarContextProvider } from 'components/molecules/searching-bar/context';
import { AuthContextProvider } from 'components/context/auth';
import { ENVIROMENT, PUSHER_API_KEY } from 'env';
import AlertProvider from 'components/molecules/alerts';
import GraphQLClient from 'domain/shared/graphql/client';

import { useDebouncedCallback } from 'use-debounce';
import Pusher from 'pusher-js/react-native';
import Badge from 'components/organisms/Badge';
import { TouchableOpacity } from 'components/atoms/touchable';
import { EventRegister } from 'react-native-event-listeners';
import { translate } from 'locales';
import { TextTransform } from 'react-native-localized-text';
import OpportunityStack from './opportunity-list';
import UserSettings from './user-settings/screens/setting-menu';
import { NotificationStack } from './notification';
import ChromeExtensionEvents from './shared/chromeExtensionEvents';
import Update from './shared/screens/update';
import useUpdate from './shared/hooks/use-update';
import CRMExtensionService from './shared/services/crm-extension-service';
import useAuth from './shared/hooks/useAuth';
import { ActivityListEvents, emitActivityListEvent } from './shared/events/activities-list';
import UserService from './shared/services/user-service';
import ViewInventoryStack from './view-inventory';
import DashboardService from './shared/services/dashboard.service';
import { FORCE_LOGOUT } from './shared/services/axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import UserProfile from './user-settings/screens/user-profile';
import PasswordAndPrivacy from './user-settings/screens/password-and-privacy';
import Preferences from './user-settings/screens/preferences';
import NotificationSettings from './user-settings/screens/notifications-settings';
import SwitchStore from './user-settings/screens/switch-store';
import { DrawerScreens, MainScreen } from './screens';
import Security from './user-settings/screens/security';
import FeedBacks from './user-settings/screens/feedback-user';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

const isIpad = (Platform as PlatformIOSStatic).isPad;
const ICON_SIZE = isIpad ? 35 : 30;

export const TAB_BER_HEIGHT = Platform.OS === 'android' ? 80 : 110;

enum CURRENT_ENV {
  DEV = 'SalesAssist Dev',
  PROD = 'SalesAssist',
}

const tabBarLabelStyle: StyleProp<TextStyle> = {
  color: colors.PRIMARY_TEXT,
  fontWeight: '600',
  marginBottom: isIpad ? 20 : 15,
  fontSize: isIpad ? 16 : 12,
  fontFamily: FONTS.SFProText,
  flexDirection: 'column',
};

const App = () => {
  const { visible } = useContext(CXTabBarContext);
  const [unRead, setUnRead] = useState(0);
  const [showInventory, setShowInventory] = useState(false);

  const { currentStore, user, logOut } = useAuth();
  const company_id = currentStore?.companies_id!;
  const userId = user?.id!;
  const dispatchNotificationEvent = useDebouncedCallback((payload: any) => {
    CRMExtensionService.setNotificationCount(payload.total);
    CRMExtensionService.onNewNotification(payload);
  }, 200);

  const notificationListener = useCallback(() => {
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: 'us2',
    });
    const channelName = `total-notification-counter-${userId}-company-${company_id}`;
    const channel = pusher.subscribe(channelName);
    channel.bind('newNotification', (payload: any) => {
      setUnRead(payload.total);
      dispatchNotificationEvent(payload);
    });
  }, [CRMExtensionService, dispatchNotificationEvent, userId, company_id]);

  const inventoryWatcher = useCallback(async () => {
    if (IS_WEB) return;
    const { total_products } = await DashboardService.get();
    if (!total_products) return;
    setShowInventory(total_products > 0);
  }, []);

  const cleanMessages = useCallback(async () => {
    await UserService.cleanNotification();
    setUnRead(0);
    CRMExtensionService.setNotificationCount(0);
    emitActivityListEvent(
      ActivityListEvents.ACTIVITY_LIST_UPDATE,
      null,
    );
  }, [CRMExtensionService]);

  useEffect(() => {
    if (company_id && userId) {
      notificationListener();
    }

    if (userId) {
      inventoryWatcher();
    }

    EventRegister.addEventListener(FORCE_LOGOUT, () => logOut());
  }, [company_id, userId, notificationListener, inventoryWatcher]);

  return (
    <Tab.Navigator
      initialRouteName={TabNavigationScreens.OPPORTUNITIES}
      screenOptions={{
        headerShown: IS_WEB,
        tabBarHideOnKeyboard: IS_ANDROID,
        header: () => <CXHeader height={visible ? TabBarHeight : 0} />,
        tabBarStyle: { height: TAB_BER_HEIGHT },
        tabBarLabelPosition: 'below-icon',
      }}
      {...IS_WEB ? {
        tabBar: (props) => IS_WEB && <CXTabBar {...props} />,
      } : {}}
    >

      <Tab.Screen
        name={TabNavigationScreens.OPPORTUNITIES}
        component={OpportunityStack}
        options={{
          tabBarLabel: translate('opportunities', TextTransform.CAPITAL),
          tabBarLabelStyle,
          tabBarIcon: ({ focused }) => (
            <Icon name="contact-page" color={!focused ? colors.PRIMARY_TEXT : colors.ORANGE} size={ICON_SIZE} />
          ),
        }}
      />

      {
        showInventory && (
          <Tab.Screen
            name={TabNavigationScreens.INVENTORY}
            component={ViewInventoryStack}
            options={{
              tabBarLabelStyle,
              tabBarIcon: ({ focused }) => (
                <MIcon name="archive-outline" color={!focused ? colors.PRIMARY_TEXT : colors.ORANGE} size={ICON_SIZE} />),
            }}
          />
        )
      }

      <Tab.Screen
        name={TabNavigationScreens.NOTIFICATION}
        component={NotificationStack}
        options={{
          tabBarLabel: translate('notifications', TextTransform.CAPITAL),
          tabBarLabelStyle,
          tabBarIcon: ({ focused }) => ((
            <TouchableOpacity onPress={cleanMessages}>
              <Badge unRead={unRead} setUnRead={setUnRead} />
              <Icon name="notifications" color={!focused ? colors.PRIMARY_TEXT : colors.ORANGE} size={ICON_SIZE} />
            </TouchableOpacity>
          )),
        }}
      />

    </Tab.Navigator>
  );
};

const DrawerStack = () => (
  <Drawer.Navigator
    initialRouteName="ROOT"
    screenOptions={{ headerShown: false, drawerStyle: { width: DEVICE_WIDTH } }}
    useLegacyImplementation={false}
    drawerContent={(props: any) => (
      <UserSettings {...props} />
    )}
  >
    <Drawer.Screen name={DrawerScreens.ROOT} component={App} />
    <Drawer.Screen name={DrawerScreens.PROFILE} component={UserProfile} />
    <Drawer.Screen name={DrawerScreens.PASSWORD_AND_PRIVACY} component={PasswordAndPrivacy} />
    <Drawer.Screen name={DrawerScreens.PREFERENCES} component={Preferences} />
    <Drawer.Screen name={DrawerScreens.NOTIFICATION_SETTINGS} component={NotificationSettings} />
    <Drawer.Screen name={DrawerScreens.SWITCH_STORE} component={SwitchStore} />
    <Drawer.Screen name={DrawerScreens.SECURITY} component={Security} />
    <Drawer.Screen name={DrawerScreens.FEEDBACK} component={FeedBacks} />
  </Drawer.Navigator>
);

function UpdateComponent() {
  useUpdate();
  return null;
}

/**
 * @description main wrapper inside the application structure
 * @returns {JSX.Element}
 */
const Application = () => {
  const [tabBarVisible, setTabBarVisible] = useState(true);
  const [CRMhost, setCRM] = useState('');

  const setCRMHost = (crm: string) => {
    setCRM(crm);
    AsyncStorage.setItem('CRM', crm);
  };

  const getCRM = async () => {
    const crm = await AsyncStorage.getItem('CRM');
    if (!crm) return;
    setCRM(crm);
  };

  useEffect(() => {
    getCRM();
  }, []);

  return (
    <ActionSheetProvider>
      <SafeAreaProvider>
        <ApolloProvider client={GraphQLClient}>
          <SearchingBarContextProvider>
            <CSTabBarContextProvider value={{
              visible: tabBarVisible,
              setTabBarVisible,
              CRMhost,
              setCRMHost,
            }}
            >
              <CXLoadingOverlay />
              <Provider store={store}>
                <NavigationContainer
                  ref={navigationRef}
                  documentTitle={{
                    formatter: () => (ENVIROMENT === 'dev' ? CURRENT_ENV.DEV : CURRENT_ENV.PROD),
                  }}
                >
                  <AuthContextProvider>
                    {
                      IS_WEB && (
                        <ChromeExtensionEvents />
                      )
                    }
                    <UpdateComponent />
                    <Stack.Navigator screenOptions={{ headerShown: false, gestureEnabled: false }}>
                      <Stack.Screen
                        name={MainScreen.Auth}
                        component={AuthStack}
                      />
                      <Stack.Screen
                        name={TabNavigationScreens.STACK_NAME}
                        component={DrawerStack}
                      />
                      <Stack.Screen
                        name={MainScreen.Update}
                        component={Update}
                        options={{ presentation: 'fullScreenModal' }}
                      />
                    </Stack.Navigator>
                  </AuthContextProvider>
                </NavigationContainer>
              </Provider>
              <Toast />
              <AlertProvider />
            </CSTabBarContextProvider>
          </SearchingBarContextProvider>
        </ApolloProvider>
      </SafeAreaProvider>
    </ActionSheetProvider>
  );
};
// const Main = Platform.OS === 'web' ? Application : codePush(Application);
const Main = Application;

export default Main;
