/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-shadow */
import Spinner from 'atoms/spinner';
import React, {
  useState, useEffect, useCallback, useRef, useContext, useMemo,
} from 'react';
import { Alert, Linking } from 'react-native';
import { connect, useDispatch, useSelector } from 'react-redux';
import ModelOption from 'atoms/ModelOption';
import Colors from 'theme/colors';
import {
  ADD_OPPORTUNITY,
} from 'domain/shared/screens';
// import { changeRefreshContacts } from 'domain/legacy/modules/session';
import { Flex } from '@kanvas/andromeda';
import { useNavigation } from '@react-navigation/native';
import { CRM_TYPES, CXTabBarContext } from 'components/organisms/CXTabBar/cx-tab-bar-context';
import CXWallHeader from 'components/molecules/CXWallHeader';
import {
  IS_CHROME_EXTENSION,
  IS_DESKTOP_WEB, IS_MOBILE, IS_MOBILE_WEB, IS_WEB,
} from 'domain/shared/constants';
import CXShortcuts from 'components/organisms/CXShorcuts';
import CustomToast from 'organisms/toast';
import { ACTION_PANEL } from 'domain/shared/types/amplitude-event';
import {
  EDIT_OPP,
  OPEN_LEAD,
  UPLOAD_FILES,
  VIEW_FILES,
} from 'domain/shared/types/amplitude-actions';
import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import { OpportunityScreens } from 'domain/opportunity-list/screens';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import styled from 'styled-components/native';
import { ACTION_ENGINE_FILE_VIEWER, PUSHER_API_KEY } from 'env';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import { TextTransform } from 'react-native-localized-text';
import { translate } from 'locales';
import UploadLeadFiles, { UploadLeadFilesRef } from 'components/organisms/upload-lead-files';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { showSuccess } from 'domain/shared/utils/toast';
import { Opportunity } from 'domain/shared/types/opportunity';
import { AppDispatch, RootState } from 'domain/shared/store';
import { clearActiveOpportunity, setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';
import ConfirmModal from 'components/molecules/confirm-modal';
import SearchApi from 'domain/opportunity-list/api/search';
import { Lead } from 'domain/opportunity-list/types';
import { refreshLeadListEvent } from 'domain/opportunity-list/screens/opp-list';
import ACTION_NAME from 'domain/shared/types/ActionName';
import useAuth from 'domain/shared/hooks/useAuth';
import Pusher from 'pusher-js/react-native';
import { useDebouncedCallback } from 'use-debounce';
import useEventReciever from 'domain/shared/hooks/use-event-reciever';
import { SCANNED_OPPORTUNITY, ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';
import Sentry from 'sentry';
import IdValidationLoading, { IDValidationLoadingState, IdValidationLoadingRef } from 'components/molecules/id-validation-loading';
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';
import usePusher from 'domain/shared/hooks/use-pusher';
import { IdValidateProcess } from 'domain/shared/hooks/use-validation-process';
import CloseLeadBanner from 'components/organisms/close-lead-banner';

interface FileManagerModalProps {
  onUploadPress: () => void;
  onViewFilePress: () => void;
}

interface IProps {
  activeOpportunity: Opportunity,
  changeActiveOpportunity: (opp: Opportunity) => void;
  clearActiveOpportunity: () => void;
  company_id:number;
  userId:number;
}

const Container = styled(Flex)`
  margin-bottom:40px;
`;

const FileManagerModal = ({ onUploadPress, onViewFilePress }: FileManagerModalProps) => {
  const { user } = useAuth();
  const canViewFiles = useMemo(() => {
    if (!user) return true;
    if (!user.access_list.fileviewer) return true;
    return user.access_list.fileviewer.can_view !== 0;
  }, []);
  return (
    <Container marginHorizontal={12} align="center">
      <ButtonWithIcon
        text={translate('upload', TextTransform.CAPITAL)}
        textColor={Colors.ORANGE}
        color={Colors.WHITE}
        style={{ marginVertical: 12 }}
        onPress={onUploadPress}
      />
      {canViewFiles && (
        <ButtonWithIcon
          text={translate('viewFiles', TextTransform.CAPITAL)}
          textColor={Colors.ORANGE}
          color={Colors.WHITE}
          onPress={onViewFilePress}
        />
      )}
    </Container>
  );
};

function useWebsocketUpdate() {
  const dispatch = useDispatch();
  const opp = useSelector((state: RootState) => state.opportunity.activeOpportunity);

  const handle = (response: any) => {
    // console.log(response);
  };

  const shouldRun = () => !!opp;

  usePusher(
    { subscribeKey: 'lead-update', bindKey: opp?.uuid || '' },
    { handle, shouldRun },
    [opp]
  );

  // setActiveOpportunity(item);
}

const ActiveSession = (props: IProps) => {
  const {
    activeOpportunity,
    changeActiveOpportunity,
    clearActiveOpportunity,
    userId,
    company_id,
  } = props;

  const navigation = useNavigation();
  const {
    id: activeOpportunityId,
    third_party_sync_status,
    custom_fields = {},
  } = ({} = activeOpportunity);

  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const timerHandler = useRef<any>(null);
  const [actionCount, setActionCount] = useState<number>();
  const [closed, setClosed] = useState(false);
  const uploadLeadFiles = useRef<UploadLeadFilesRef>(null);
  const idVerificationScreenRef = useRef<IdValidationLoadingRef>(null);
  const companySettings = useCompanySettings();
  const [openIdValidationModal, setOpenIdValidationModal] = useState(false);

  const { setTabBarVisible, CRMhost } = useContext(CXTabBarContext);
  useWebsocketUpdate();

  useEffect(() => {
    if (IS_WEB) {
      setTabBarVisible(false);
      return () => {
        setTabBarVisible(true);
      };
    }
  }, [activeOpportunityId, setTabBarVisible]);

  const closeSession = useCallback(() => {
    const { clearActiveOpportunity } = props;
    clearActiveOpportunity();
    changeActiveOpportunity(activeOpportunity);
    // changeRefreshContacts({ refreshContacts: true });
    timerHandler.current?.stopTimer();
    navigation.goBack();
  }, [props, navigation]);

  const deleteLead = useCallback(() => {
    Alert.alert('Remove leads', 'do you want to remove the lead?', [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'OK',
        onPress: async () => {
          const { status } = await OpportunityService.delete(activeOpportunityId);
          if (status === 200) {
            setTimeout(() => {
              closeSession();
            }, 500);
          }
        },
      },
    ]);
  }, [activeOpportunityId, closeSession]);

  const validatePrompt = useCallback(async () => {
    Alert.alert('This lead was not able to integrate with the CRM.', '', [
      {
        text: 'Delete',
        onPress: () => {
          deleteLead();
        },
      },
      {
        text: 'Try Again',
        onPress: async () => {
          const opportunity = await OpportunityService.update(
            {} as Opportunity,
            activeOpportunityId,
          );
          if (opportunity.third_party_sync_status === 1) {
            toggleLoading(false);
            changeActiveOpportunity(activeOpportunity);
            showSuccess(translate('leadIntegratedCRM', TextTransform.CAPITAL));
            return;
          }
          validatePrompt();
        },
      },
    ]);
  }, [activeOpportunityId, changeActiveOpportunity, deleteLead]);

  const handleCloseOpp = useCallback(() => {
    refreshLeadListEvent();
    navigation.goBack();
  }, []);

  const validateCrmSync = useCallback(async () => {
    if (CRMhost === CRM_TYPES.vin) return;
    toggleLoading(true);
    try {
      // const item = activeOpportunity as unknown as Lead;
      // const lead = await SearchApi.find(item.link_sources.e_leads.lead_uuid);
      // const currentLead = Array.isArray(lead) ? lead[0] : lead;
    //   if (currentLead.status_name !== 'Active') setClosed(true);
      if (third_party_sync_status === 0) validatePrompt();
    } finally {
      if (third_party_sync_status !== 0) toggleLoading(false);
    }
  }, [third_party_sync_status, validatePrompt, activeOpportunity, CRMhost]);

  useEffect(() => {
    validateCrmSync();
  }, [validateCrmSync]);

  const getActionShared = async () => {
    setActionCount(activeOpportunity.total_shared_actions);
  };

  const dispatchNotificationEvent = useDebouncedCallback((payload: any) => {
    CRMExtensionService.setNotificationCount(payload.total);
    CRMExtensionService.onNewNotification(payload);
  }, 200);

  const notificationListener = useCallback(() => {
    const {} = props;
    const pusher = new Pusher(PUSHER_API_KEY, {
      cluster: 'us2',
    });
    const channelName = `total-notification-counter-${userId}-company-${company_id}-lead-${activeOpportunityId}`;
    const channel = pusher.subscribe(channelName);
    channel.bind('newNotification', (payload: any) => {
      const { action } = payload;
      switch (action) {
        case ACTION_NAME.CREDIT_APP:
          CRMExtensionService.showNotificationBaner(ACTION_NAME.CREDIT_APP, payload);
          break;
        case ACTION_NAME.GET_DOCS:
          CRMExtensionService.showNotificationBaner(ACTION_NAME.GET_DOCS, payload);
          break;
        case ACTION_NAME.CO_SIGNER:
          CRMExtensionService.showNotificationBaner(ACTION_NAME.CO_SIGNER, payload);
          break;
        default:
          break;
      }
    });
  }, [dispatchNotificationEvent]);

  useEffect(() => {
    if (custom_fields) {
      const {
        credit_app_imported,
        get_docs_imported,
        co_buyer_imported,
      } = custom_fields as any;

      if (credit_app_imported && credit_app_imported.active) {
        CRMExtensionService.showNotificationBaner(ACTION_NAME.CREDIT_APP, credit_app_imported);
      }
      if (get_docs_imported && get_docs_imported.active) {
        CRMExtensionService.showNotificationBaner(ACTION_NAME.GET_DOCS, get_docs_imported);
      }
      if (co_buyer_imported && co_buyer_imported.active) {
        CRMExtensionService.showNotificationBaner(ACTION_NAME.CO_SIGNER, co_buyer_imported);
      }
    }
  }, []);

  useEffect(() => {
    getActionShared();
  }, [activeOpportunity?.uuid]);

  useEffect(() => {
    notificationListener();
  }, []);

  // @ts-ignore
  const editOpp = () => {
    setTimeout(() => {
      const params = {
        screen: ADD_OPPORTUNITY,
        params: { editMode: true },
      };
      // @ts-ignore
      navigation.navigate(OpportunityScreens.CREATE, params);
    }, 600);

    sendAmplitudeEvent(EDIT_OPP, { event: ACTION_PANEL, activeSessionId: activeOpportunityId });
  };

  const linkHandler = useCallback((leadId: string, title: string) => {
    const link = `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadId}`;
    
    if (IS_MOBILE_WEB || IS_MOBILE) {
      setTimeout(() => {
        OpenInAppModalBrowser(link);
      }, 600);
      return;
    } 

    if (IS_CHROME_EXTENSION) {
      CRMExtensionService.openFileViewer(link, title || '');
      return;
    }
    
    if (IS_DESKTOP_WEB) {
      Linking.openURL(link);
      return;
    }
  }, []);

  // const editContact = useCallback(() => {
  //   closeModal();
  //   setTimeout(() => {
  //     navigation.navigate(EDIT_CONTACT);
  //   }, 600);
  //   const { data: { user: { id } } } = auth;
  //   sendAmplitudeEvent(EDIT_PEOPLE, { event: ACTION_PANEL }, id);
  // }, [navigation]);

  // const editLead = useCallback(() => {
  //   closeModal();
  //   setTimeout(() => {
  //     navigation.navigate(EDIT_SESSION);
  //   }, 600);

  //   const { data: { user: { id } } } = auth;
  //   sendAmplitudeEvent(EDIT_OPP, { event: ACTION_PANEL }, id);
  // }, [navigation]);

  const onUploadPress = useCallback(() => {
    sendAmplitudeEvent(UPLOAD_FILES, { event: UPLOAD_FILES, leadId: activeOpportunity.uuid });
    uploadLeadFiles.current?.openCamera();
    setIsFileModalVisible(false);
  }, [navigation, activeOpportunity]);

  const onViewFilePress = useCallback(() => {
    sendAmplitudeEvent(VIEW_FILES, { event: VIEW_FILES, leadId: activeOpportunity.uuid });
    setIsFileModalVisible(false);
    linkHandler(activeOpportunity.uuid, activeOpportunity.title);
  }, [activeOpportunity]);

  const onFileSelected = useCallback(() => {
    setIsFileModalVisible(false);
  }, []);

  useEffect(() => () => clearActiveOpportunity(), []);

  useEventReciever(SCANNED_OPPORTUNITY, async (data: ScannerParams) => {
    if (data.type !== 'wall') return;
    if (!data.opp) return;
    if (!companySettings?.id_verification) return;

    setOpenIdValidationModal(true);

    try {
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.VALIDATING);
      const idVerification = await IdValidateProcess.instance(companySettings).verify(data.opp);

      const { extra: extraData, firstname, middlename, lastname } = data.opp;
      const { expDate,  ...extra } = extraData;
  
      const getDocs = (activeOpportunity.custom_fields as any)?.get_docs_drivers_license || {};
      const getDocsData = {  ...getDocs, ...extra, exp_date: expDate, firstname, middlename, lastname, };
  
      const request = { 
        id_verification: idVerification,
        title: activeOpportunity.title,
        driver_license_images: data.opp.pictures,
        get_docs_drivers_license: getDocsData,
      };

      // @ts-ignore
      const current = await OpportunityService.update(request, activeOpportunity.id);
      changeActiveOpportunity(current);
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.SUCCESS);
    } catch (e) {
      Sentry.captureException(e);
      idVerificationScreenRef.current?.changeStatus(IDValidationLoadingState.FAIL);
    }
  });

  return (
    <Flex flex={1}>
      <IdValidationLoading 
        open={openIdValidationModal}
        ref={idVerificationScreenRef}
        onClose={() => setOpenIdValidationModal(false)}
      />
      <UploadLeadFiles
        ref={uploadLeadFiles}
        opportunity={activeOpportunity}
        onFileSelected={onFileSelected}
      />
      <ConfirmModal
        hideCancelButton
        visible={closed}
        text={translate('oppClose')}
        onClose={handleCloseOpp}
        onOk={handleCloseOpp}
      />
      <CXWallHeader
        activeSession={activeOpportunity}
        openFV={setIsFileModalVisible}
        openModal={editOpp}
        actionCount={actionCount || 0}
      />

      <CloseLeadBanner />

      {isLoading ? (
        <Spinner />
      ) : (
        <Flex flex={1}>
          <CXShortcuts activeOpportunity={activeOpportunity} />
          <Flex>
            <ModelOption
              header={false}
              isVisible={isFileModalVisible}
              children={(
                <FileManagerModal
                  onUploadPress={onUploadPress}
                  onViewFilePress={onViewFilePress}
                />
              )}
              onPressClose={() => setIsFileModalVisible(false)}
            />
          </Flex>
        </Flex>
      )}
      {/*
        Using toast element inside modal screen to allow toast message display on front of the UI
      */}
      <CustomToast position="bottom" />
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeOpportunity: state.opportunity.activeOpportunity!,
  userId: state.auth?.user?.id,
  company_id: state.auth.user?.default_company_branch,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearActiveOpportunity: () => dispatch(clearActiveOpportunity()),
  changeActiveOpportunity: (item: Opportunity) => dispatch(setActiveOpportunity(item)),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ActiveSession);
